@font-face {
    font-family: 'MyCustomFont';
    src: url('./Assets/Font/Poppins-Regular.ttf') format('woff2'),
         url('./Assets/Font/Poppins-Regular.ttf') format('woff'),
         url('./Assets/Font/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  body {
    font-family: 'Poppins', sans-serif !important;
  }