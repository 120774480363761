* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

/* --------- */
.sticky {
  background-color: #ffffff9e !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #fff !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

/* .logo {
    height: 1.4em !important;
    width: 2.5em !important;
  }
   */
.navbar-nav .nav-link {
  color: #fff !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-size: 0.875rem;
  font-weight: bold;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
  text-decoration: none;
  color: #fff;
}
.sticky .navbar-nav .nav-item a {
  color: #000 !important;
}
/* .navbar-nav .nav-item a.active{
font-weight: 800;
} */
.sticky .navbar-nav .nav-item a.active::after {
  background: #000;
}

.navbar-nav .nav-item a.active::after {
  content: "";
  position: relative;
  display: block;
  height: 2px;
  width: 0;
  border-radius: 16px;
  background: #fff;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
  width: 100%;
}
.sticky .navbar-nav .nav-item a::after {
  background: #000;
}
.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 2px;
  width: 0;
  border-radius: 16px;
  background: #fff;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* home  */
.home {
  height: 100vh;
  color: #000;
}
.home .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    46.59685939489132% 46.59685939489132% at 50.00000555477855%
      53.403141993803125%,
    #000000 0%,
    rgba(0, 0, 0, 0.800000011920929) 100%
  );
  opacity: 0.9;
  z-index: -1; /* Make sure it's below the video */
}
.home video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}
.home .title h2 {
  color: #ff7300;
  font-size: 4rem;
  font-weight: bold;
}
.home .title p {
  color: #fff;
}

.home-btn {
 --border-bottom-width: 1px;
    --border-color: #000000;
    --border-left-width: 1px;
    --border-right-width: 1px;
    --border-style: solid;
    --border-top-width: 1px;
    -webkit-backdrop-filter: blur(40px);
      color: #fff;
    backdrop-filter: blur(40px);
    background: linear-gradient(180deg, #ffffff6b, #181818cc);
    border-radius: 16px;
    display: inline-block;
    padding: 16px 32px;
    position: relative;
  cursor: pointer;
}
.home-btn:hover{
  color: #ff7300 !important;
}
.home-btn:hover a{
  color: #ff7300 !important;

}

.home-btn a {
  color: #fff;
  text-decoration: none;
}
.home-btn:hover {
  color: #fff;
}
.home-btn:hover{
  background: linear-gradient(360deg, #ffffff6b, #181818cc);
}
.home-btn:hover a {
  color: #fff;
}

.home .marquee-setion {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  background-color: #ff7300;
  color: #fff;
  height: 72px;
  width: 100%;
  transform: rotate(1deg);
}

.home .marquee-container {
  display: flex;
  align-items: center;
  overflow: hidden; /* Ensures the content doesn't overflow */
  white-space: nowrap; /* Prevents line breaks in the content */
}
.home .marquee-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px; /* Adjust padding as needed */
}
.home .marquee-setion span {
  color: #000;
  font-size: 1.5rem;
  font-weight: bold;
}

.home .marquee-setion img {
  margin: 0 20px;
}
/*  */
.ignite-results video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}
.ignite-results .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    46.59685939489132% 46.59685939489132% at 50.00000555477855%
      53.403141993803125%,
    #000000 0%,
    rgba(0, 0, 0, 0.800000011920929) 100%
  );
  opacity: 0.7;
  z-index: -1;
}
.ignite-results {
  position: relative;
  /* height: 600px; */
  height: 100vh;
  width: 100%;
}

.ignite-results .content h1 {
  font-size: 72px;
  font-weight: bold;
  color: #ff7300;
}

.ignite-results .content p {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}

/* preloader */

/* #preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  background-color: transparent;
}

#preloader h2 {
  font-size: 6rem;
  color: #fd6202;
  transition: opacity 300ms ease-in-out 500ms;
}

#preloader.loaded h2 {
  opacity: 0;
}

#preloader:before,
#preloader:after {
  content: "";

  position: absolute;
  inset: 0;
  width: 50%;
  height: 100%;
  transition: all 0.3s ease 0s;
  z-index: -1;
  display: flex;
  justify-content: center; 
  align-items: center; 
  background-color: #000;
}

#preloader:after {
  left: auto;
  right: 0;
}
#preloader.loaded:before,
#preloader.loaded:after {
  animation: preloaderfinish 500ms ease-in-out 600ms forwards;
}

@keyframes preloaderfinish {
  0% {
    width: 5 0%;
  }
 
  100% {
    width: 0%;
  }
} */

#preloader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  background-color: transparent;
  overflow: hidden;
  padding: 20px;
}

#preloader h2 {
  font-size: 6rem;
  font-weight: bold;
  color: #fd6202;
  transition: opacity 300ms ease-in-out 500ms;
  -webkit-transition: opacity 300ms ease-in-out 500ms;
  -moz-transition: opacity 300ms ease-in-out 500ms;
  -o-transition: opacity 300ms ease-in-out 500ms;
}
#preloader img {
  transition: opacity 300ms ease-in-out 500ms;
  -webkit-transition: opacity 300ms ease-in-out 500ms;
  -moz-transition: opacity 300ms ease-in-out 500ms;
  -o-transition: opacity 300ms ease-in-out 500ms;
}
#preloader.loaded h2,
#preloader.loaded img {
  opacity: 0;
}

#preloader:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transition: height 0.5s ease 0.6s;
  -webkit-transition: height 0.5s ease 0.6s;
  -moz-transition: height 0.5s ease 0.6s;
  -o-transition: height 0.5s ease 0.6s;
  z-index: -1;
  background-image: url(../images/preloaderbg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#preloader.loaded:before {
  height: 0;
}

/* footer */
.footer {
  padding: 20px;
}

.footer .title {
  color: #7a7a7a;
  font-size: 1rem;
}
.footer a {
  color: #1d1d1d;
  font-size: 1rem;
  text-decoration: none;
  margin: 0 10px;
  text-decoration: underline;
}

.footer .main-title {
  color: #7a7a7a;
  font-size: 1.5rem;
}
.footer .main-subtitle {
  color: #fd6504;
  font-size: 1.5rem;
  font-weight: 900;
}

.footer .socialmedia-icons a svg {
  margin-right: 1rem;
  font-size: 1.5rem;
}
.footer .socialmedia-icons a svg:hover {
  color: #ff7300;
}

@media (max-width: 767px) {
  #preloader h2 {
    font-size: 3rem;
  }
  .home .title h2 {
    font-size: 2rem;
  }
  .ignite-results .content h1 {
    font-size: 2rem;
  }
}

/* banner */

.banner {
  height: 50vh;
  width: 100%;
  position: relative;
}
.banner video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}
/* .banner .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    46.59685939489132% 46.59685939489132% at 50.00000555477855%
      53.403141993803125%,
    #000000 0%,
    rgba(0, 0, 0, 0.800000011920929) 100%
  );
  opacity: 0.9;
  z-index: -1;
} */
.banner .banner-title h2 {
  color: #ff7300;
  font-size: 3.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px #0000004f;
}

/* faq */
.faq-section {
  padding: 80px 0;
}
.faq-section .title {
  margin-bottom: 40px;
}
.faq-section .title h2 {
  margin-bottom: 0.5rem;
  font-size: 3rem;
  font-weight: bold;
}
.faq-section .custom-accordion-header button {
  color: #1d1d1d;
  font-weight: 600;
  padding: 0 !important;
  background-color: transparent !important;
}
.faq-section .accordion-item {
  border: none !important;
}
.faq-section .accordion-item:hover .svg-inline--fa {
  color: #ff7300;
}
.faq-section .accordion-item .acc-bg {
  padding: 16px;
  background-color: #f1f1f1;
  margin-bottom: 16px;
  border-radius: 8px;
}
.faq-section .custom-accordion-header button::after {
  display: none;
}
.faq-section .custom-accordion-header .accordion-button:not(.collapsed) {
  background-color: transparent;
  color: #000;
  box-shadow: none;
}
.faq-section .custom-accordion-header .accordion-button:focus {
  box-shadow: none;
}

.faq-section .accordion-collapse .accordion-body {
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
  color: #333333;
  padding: 0 !important;
}

/* career */
.career-content {
  padding: 100px 0 0 0;
  background-image: url(../images/career-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.career-content h2 {
  font-size: 3rem;
  font-weight: bold;
  color: #fff;
}
.career-content p {
  color: #fff;
  font-size: 18px;
}

.career-content .content-footer img {
  padding: 0 10px;
}

/* contact */

.required:after {
  content: " *";
  color: red;
}
.contact-content .form-check-input.is-invalid~.form-check-label{
  color: #000 !important;
}
.contact-content .form-check-input.is-invalid{
  border-color: #000 !important;
}
.contact-content {
  margin: 152px;
}
.contact-content h1 {
  font-size: 48px;
  font-weight: bold;
}
.contact-content .text span {
  color: #fd6504;
  font-size: 48px;
  font-weight: bold;
}
.contact-content .text span:nth-child(2) {
  color: #000;
}
.contact-form label {
  font-size: 14px;
  color: #000;
  font-weight: 600;
}
.contact-form .form-label{
  font-weight:600;
}
.contact-form input, .contact-form textarea {
  border: 1px solid #999999;
  background-color: #8e8e8e29;

}
.form-check input + label{
  font-weight: unset;
}
.form-check label{
color: #8E8E8E
}
.contact-form label + input {
  height: 48px;
}
.contact-form input:checked {
  background-color: #000000 !important;
  border-color: #000000 !important;
}
.contact-form input::placeholder,
.contact-form textarea::placeholder {
  font-size: 14px;
  color: #8e8e8e;
}
.contact-form #formBusinessType label:nth-child(1),
.contact-form #formLookingFor label:nth-child(1) {
  color: #000;
}
.contact-form #formBusinessType label,
.contact-form #formLookingFor label {
  color: #8e8e8e;
}
.contact-btn.home-btn {
  color: #fff !important;
  padding: 16px 32px !important;
  background: linear-gradient(
    1deg,
    rgb(49 48 48 / 83%) 0%,
    rgb(110 110 110 / 30%) 100%
  ) !important ;
  border: none !important;
  border-radius: 16px !important;
  font-weight: 600;
}
.contact-btn.home-btn:hover {
  background: linear-gradient(
    180deg,
    rgb(49 48 48 / 83%) 0%,
    rgb(110 110 110 / 30%) 100%
  ) !important ;
  color: #ff7300 !important;
}
/* resource */
.resource-main .row {
  padding: 20px;
  background-color: #f1f1f1;
  border-radius: 16px;
}
.resource-content img {
  float: left;
  margin: 0 20px 10px 0;
  border-radius: 12px;
}
.resource-content h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #1d1d1d;
}
.resource-content p {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.resource-content h4 {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.resource-content .readmore-btn {
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  color: #1d1d1d;
  border: 1px solid #1d1d1d;
  border-radius: 12px;
  padding: 8px 16px;
}

.resource-content .readmore-btn:hover {
  color: #fff;
  border: 1px solid #fd6504;
  border-radius: 12px;
  background-color: #fd6504;
}

.animated-subpara {
  color: red;
}

/*  */

@media (max-width: 767px) {
  .navbar {
    padding: 0.5rem !important;
    font-size: 1.4rem !important;
    background-color: #00000052 !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
  .sticky .navbar-nav .nav-item a {
    color: #fff !important;
    padding: 10px 6px !important;
  }
  .banner .banner-title h2 {
    font-size: 2.5rem;
  }
  .ignite-results {
    height: auto;
    padding: 40px 0;
  }
  .contact-form .row {
    display: grid;
  }
  .resource-content img {
    float: none;
    margin: 0 0 10px 0;
  }
  .carousel-bg .header {
    position: static !important;
    margin-bottom: 10px !important;
  }
  .contact-content{
    margin: 0;
    padding: 10px;
  }
}

@media (max-width: 991px) {
  .navbar-nav .nav-link {
    color: #fff !important;
  }
  .navbar-nav .nav-item {
    margin-left: 0 !important;
  }
}

@media (max-width: 375px) {
  .animated-text p > span {
    font-size: clamp(1.2rem, 0vw + 1rem, 1rem);
  }
}
@media (min-width: 375px) and (max-width: 667) {
  .animated-text p > span {
    font-size: clamp(1rem, 0vw + 1rem, 1rem);
  }
}

/* landscape */

@media (max-width: 991px) and (orientation: landscape) {
  .home .title h2 {
    font-size: 2rem;
  }
  .animated-section .animated-text p > span {
    font-size: clamp(1rem, 1vw + 1rem, 2rem);
  }
  .carousel-bg {
    height: auto !important;
    padding: 200px 0;
  }
  .carousel-bg .header {
    top: 10% !important;
  }
  .banner {
    height: 100vh;
  }
  .banner .banner-title h2 {
    font-size: 2.5rem;
  }
}
/* ipad */

@media (min-width: 768px) and (max-width: 1024) {
  .animated-text p > span {
    font-size: clamp(2rem, 1vw + 1rem, 2rem);
  }
}
